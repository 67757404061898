import React from 'react';
import './App.css';
//code
function App() {
  return (
    <>
    <div className='bar'></div>
    <div className="App">
      <div className="App-header">
        <h1>
          I am <span className='highlight'>W</span>esley <span className='highlight'>J</span>acobs
        </h1>
        <h4>I've been away from home for a very long time. I constantly feel like my friends and family don't know who I am anymore... They probably don't. I wanted to share some things about myself for anyone who cares. If you don't then <span className='highlight'>that's cool too</span>. This is not all of me, but more like a starter pack. 
        </h4>
      </div>
      <div className='App-body'>
        <p className='belief'>
        I am <span className='highlight'>open-minded</span>, will listen to, and respect all beliefs except those that speak <span className='badSpan'>hate</span> or devalue another persons beliefs and freedoms.
        </p>
        <p className='belief'>
        I come from a "Christian" background and still believe in the values I learned as a child such as... 
        <ul>
          <li className='listBelief'>Love your neighbor as yourself.</li>
          <li className='listBelief'>Treat others the way you would want to be treated.</li>
          <li className='listBelief'>Take care of those who cannot take care of themselves.</li>
          <li className='listBelief'><span className='big'>All</span> are deserving of love. </li>
          <li className='listBelief'>I am no ones judge.</li>
          <li className='listBelief'>I am also not perfect and would do well to remember that.</li>
          <li className='listBelief italic'> I believe in God.</li>
          <li className='listBelief'>I believe the church is broken, imperfect, and sick because it is full of people that are the same. That is <span className='big'>supposed</span> to be the point.</li>
          <li className='listBelief'>There are good Christians and bad Christians and it's sometimes a fluid spectrum between them.</li>
          <li className='listBelief'>There is a lot more that I <span className='highlight'>don't</span> know than what I do, and that is also okay.</li>
          <li className='listBelief italic'>I will show everyone the love I feel God showed me and He will take care of the rest.</li>
        </ul>
        </p>
        <p className='belief'>
        I believe our society is broken by greed. Businesses look at us as possible sources of income to be mined and don't consider the human. They also seem to run our government <span role='img' aria-label='angry-face'>😡</span>. I want that to change.
        </p>
        <p className='belief'>
        We are more <span className='highlight'>similar</span> than we are <span className='badSpan'>different</span>.
        </p>
        <p className='belief'>
        I believe Tuesdays were made for <span className='big'>Tacos</span> and <span className='big'>Shiner</span>.
        </p>
        <p className='belief'>
        Dogs are perfect and should be treated like angels in furry flesh.
        </p>
        <p className='belief'>
        Animals should never be treated cruelly, I'm not a vegetarian but I believe they deserve to be treated with care to the best of our ability because to treat them poorly speaks more to our character than to their value.
        </p>
        <p className='belief'>
        Healthcare and education is a <span className='highlight'>human right</span>. As a species, we should be aligned to care for and help each other be better for the sake of our children.
        </p>
        <p className='belief'>
        We have one world. Can we stop <span role='img' aria-label='poop'>💩</span>-ing on it please? This is why we can't have nice things. 
        </p>
        <p className='belief'>
        I believe no person should be able to tell another what they can do with their own body. Specifically <span className='big'>women and minorities</span> have been treated as second class for most of history and it's time we treated each other as equals... <span className='highlight'>because we are</span>.
        </p>
        <p className='belief'>
        I don't like abortion and hate that it happens but it's not my body. It's not my decision. I support you to make whatever decision you think is best for you, your life, and your future.
        </p>
        <p className='belief'>
        Just because you can doesn't mean you should. Looking at you <span className='badSpan'>white men</span>.
        </p>
        <p className='belief'>
        I believe in aliens. We are not alone. The universe is vast and there is too much we don't know.
        </p>
        <p className='belief'>
        I love fantasy books, DnD, anime, computers, games, and nerdy things. If you love something no one should shame you for it. <span className='italic'>Unless it's harmful to another</span>.
        </p>
        <p className='belief'>
        I assume others mean well until I am proven wrong by actions and conversations. 
        </p>
        <p className='belief'>
        I love people and hearing their stories.
        </p>
        <p className='belief'>
        I'm motivated to help others achieve their goals and live their lives to the fullest. If you can dream it, let me help you get there. <span className='highlight big'>We can do anything together</span>. 
        </p>
        <p className='belief'>
        I like cheesy things and silly puns. Obviously.
        </p>
        <p className='belief'>
        I'm very forgetful and definitely missed a lot.
        </p>
        
        <h4 className='finalTag'>This is not the entire list. Just like me... It will change. That much you can rely on. If you don't agree with me then I ask you respect my right to have my own thoughts and perspectives. Agree to disagree, bond around what we DO have in common and move on. If you want to ask me questions about WHY I feel this way then just ask.</h4>
        <p className='belief'>
        TL;DR - I'm chill. Love yourself, love others. Don't be a dick.
        </p>
      </div>
    </div>
    </>
  );
}

export default App;
